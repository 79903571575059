// @import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    font-family: 'Outfit';
    background-color: #f8fffb;
}

// body.dark {
//     background-color: #041a37;
//     color: white;

//     table {
//         thead {
//             background-color: #D7B56D;
//         }
//     }

//     input, select {
//         color: black;
//     }
// }


/* Global Scrollbar */

/* Works on Firefox */
* {
    scrollbar-width: thin;
    scrollbar-color: #D7B56D #EBF1F9;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
    width: 7px;
    height: 7px;
}

*::-webkit-scrollbar-track {
    background: #EBF1F9;
    border-radius: 5px;
}

*::-webkit-scrollbar-thumb {
    background-color: #D7B56D;
    border-radius: 20px;
    border-radius: 5px;
}

/* Global Calender */
.react-date-picker__wrapper {
    border: none !important;
}

.react-date-picker__clear-button {
    display: none !important;
}


/* Global Placeholder */
::-webkit-input-placeholder {
    text-transform: capitalize;
}

:-moz-placeholder {
    text-transform: capitalize;
}

::-moz-placeholder {
    text-transform: capitalize;
}

:-ms-input-placeholder {
    text-transform: capitalize;
}

.apex {
    border: 1px solid black!important;
    padding: 8px !important;
    border-radius: 5px !important;
    background-color: #1D1D1D !important;
    max-width: 300px !important;
}
